<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" :disabled="isDisabled" @click="onPressNewProdSupplier('supplierData')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Нийлүүлэгчийн мэдээлэл засах'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="supplierData" :rules="supplierRules" ref="supplierData">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="supplier_monName">
                        <el-input v-model="supplierData.supplier_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="supplier_engName">
                      <el-input v-model="supplierData.supplier_engName" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Регистрийн дугаар" prop="supplier_regno">
                        <el-input v-model="supplierData.supplier_regno" placeholder="6183352" v-on:input="numberValidate()"></el-input>
                      </el-form-item>
                    </el-col>
                     <el-col :span="12">
                        <el-form-item label="Байгууллага">
                          <el-input placeholder="" disabled type="text" v-model="supplierData.EbarimtSupplier"></el-input>
                        </el-form-item>
                      </el-col>
                      <!-- <el-col :span="6" class="mt20 text-center">
                        <el-form-item label="">
                          <el-checkbox v-model="supplierData.package_connetion_check">Багцын холболттой эсэх</el-checkbox>
                        </el-form-item>
                      </el-col> -->
                      <el-col :span="12">
                        <el-form-item label="Утасны дугаар" prop="phone">
                          <el-input v-model="supplierData.phone" maxlength="12" placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Нэмэлт утасны дугаар">
                          <el-input v-model="supplierData.phone2" maxlength="12" placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                      <!-- <el-col :span="12">
                        <el-form-item label="Төлөв">
                          <el-switch
                            style="display: block"
                            v-model="supplierData.isActive"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-text="Идэвхитэй"
                            inactive-text="Идэвхгүй"
                            class="mt10 text-center">
                          </el-switch>
                        </el-form-item>
                      </el-col> -->
                      <el-col :span="12">
                        <el-form-item label="Хэрэглэгчийн Diamond код">
                          <el-input v-model="supplierData.diamond_code" placeholder="123456"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Имэйл">
                    <el-input v-model="supplierData.supplier_email" type="email" rows="3" placeholder="email"></el-input>
                  </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Хариуцсан менежер">
                    <el-input v-model="supplierData.in_charge_manager" type="text" rows="3" placeholder="Нэр"></el-input>
                  </el-form-item>
                      </el-col>
                      <el-col :span="24">
                      <el-form-item>
                        <el-checkbox v-model="supplierData.is_tax_paid">НӨАТ төлөгч</el-checkbox>
                      </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="НӨАТ">
                          <el-radio-group v-model="supplierData.ebarimt_type">
                            <el-radio :label="`supplier`">Нийлүүлэгч</el-radio>
                            <el-radio :label="`package_connetion`">Багцын</el-radio>
                            <el-radio :label="`from_us`">Манайхаас</el-radio>
                            <el-radio :label="`NOATgui`">НӨАТ-гүй</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="Гэрээний төлөв">
                          <el-radio-group v-model="supplierData.supplier_status">
                            <el-radio :label="`contract_denied`">Цуцлагдсан</el-radio>
                            <el-radio :label="`contract_pending`">Хүлээгдэж байгаа</el-radio>
                            <el-radio :label="`contract_accepted`">Амжилттай</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                      <el-form-item label="Хаяг" prop="address">
                    <el-input v-model="supplierData.address" type="textarea" rows="3" placeholder="ХУД 3-р хороо Гоёо"></el-input>
                  </el-form-item>
                  <el-form-item label="Байршил">
                    <el-input v-model="supplierData.location" disabled></el-input>
                  </el-form-item>
                  <div class="" style="width: 100%; height: 400px; display:block">
                    <gmap-map
                      :center="googleMap.center"
                      :zoom="12"
                      :options="googleMap.options"
                      :draggable="false"
                      style="width:100%;  height: 100%; margin-top: 5px;"
                    >
                    <gmap-marker
                      :position="googleMap.location"
                      :clickable="true"
                       @position_changed="changeLocation($event)"
                      :draggable="true"/>
                    </gmap-map>
                  </div>
                </div>
              </div>
            </el-col>
            <!-- Right brands -->
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <!-- <header>Ажиллах цагийн хуваарь</header>
                  <el-form-item :label="day.day" v-for="(day, indexDay) in week" :key="indexDay">
                    <el-time-select
                      style="width: 40%"
                      :disabled="day.is_weekend"
                      size="mini"
                      placeholder="Нээх цаг"
                      v-model="day.start_time">
                    </el-time-select>
                    <el-time-select
                      style="width: 40%"
                      class="ml5"
                      size="mini"
                      :disabled="day.is_weekend"
                      placeholder="Хаах цаг"
                      v-model="day.end_time">
                    </el-time-select>
                    <el-tooltip class="item" effect="light" content="Амрах өдөр" placement="top">
                      <el-checkbox class="ml10" v-model="day.is_weekend" @change="generateDateTime(day)"></el-checkbox>
                    </el-tooltip>
                  </el-form-item> -->
                  <header>Нийлүүлэгчийн брэндүүд</header>
                  <el-table
                  :data="supplierData.brands"
                  @cell-click="onPressBrand"
                  style="width: 100%">
                  <el-table-column
                    prop="brand_monName"
                    label="Брэнд"
                    width="180"
                    align="center">
                  </el-table-column>
                  <el-table-column
                    label="Зураг"
                    align="center">
                     <template slot-scope="scope">
                      <el-row :gutter="10" align="middle" type="flex">
                        <el-col :span="12" :offset="6">
                          <div class="image-holder">
                            <img :src="scope.row.brand_logo">
                           </div>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  </el-table>
                </div>
              </div>
               <!-- dialog view history -->
                <div class="panel-item pb20">
                  <el-form-item>
                    <el-button v-if="historyTotal != '0'" type="success" class="block" @click="dialogVisibleHistory = true">Түүх үзэx</el-button>
                  </el-form-item>
                  </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button class="text-left" type="danger" @click="onChange('delete')">Устгах</el-button>
              </el-col>
              <el-col :span="16" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onChange('update')
                ">Шинэчлэх</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
     <!-- view dialog -->
    <el-dialog title="Түүх харах" :visible.sync="dialogVisibleHistory" width="50%">
        <el-table :data="historyData"
        @cell-click="showHistoryDialog"
        style="width: 100%">
          <el-table-column
          label="Date" prop="created_at" width="180">
          </el-table-column>
          <el-table-column
          prop="user_name" label="Хэрэглэгч" width="240">
          </el-table-column>
          <el-table-column prop="type" label="Үйлдэл"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exitHistory">Буцах</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Түүх дэлгэрэнгүй харах" center :visible.sync="historyDetail" :width="historyDetailoldData === null ? '40%' : '80%'">
      <el-row :gutter="20">
      <loader :active="historyLoading" is-screen />
        <el-col :span="12" v-if="historyDetailoldData !== null">
          <el-row :gutter="20">
            <el-card>
              <el-form :model="historyDetailoldData" :disabled="true">
                <el-row :gutter="20">
                  <span><strong>Хуучин</strong> - {{historyDetailoldData.created}}</span>
                </el-row>
                <el-col :span="12">
                  <el-form-item label="Нэр">
                    <el-input v-model="historyDetailoldData.supplier_monName">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Нийлүүлэгчийн имэйл">
                    <el-input v-model="historyDetailoldData.supplier_email"></el-input>
                  </el-form-item>
                  <el-form-item label="Байгууллагын регистэр">
                    <el-input v-model="historyDetailoldData.supplier_regno">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Утас">
                    <el-input v-model="historyDetailoldData.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="НӨАТ">
                    <el-input v-model="this.oldEbarimtType"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Supplier name">
                    <el-input v-model="historyDetailoldData.supplier_engName">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Хариуцсан менежер">
                    <el-input v-model="historyDetailoldData.in_charge_manager"></el-input>
                  </el-form-item>
                  <el-form-item label="Байршил">
                    <el-input v-model="historyDetailoldData.location">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Хаяг">
                    <el-input v-model="historyDetailoldData.address"></el-input>
                  </el-form-item>
                  <el-form-item label="Diamond код">
                    <el-input v-model="historyDetailoldData.diamond_code"></el-input>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="historyDetailoldData === null ? 24 : 12" class="l10" v-if="historyDetailnewData !== null">
          <el-row :gutter="20">
            <el-card>
              <el-form :model="historyDetailnewData" :disabled="true">
                <el-row :gutter="20">
                  <span><strong>Шинэ</strong> - {{historyDetailnewData.created}}</span>
                </el-row>
                <el-col :span="12">
                  <el-form-item label="Нэр" :class="changeLog(historyDetailoldData ? historyDetailoldData.supplier_monName : historyDetailnewData.supplier_monName, historyDetailnewData.supplier_monName) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.supplier_monName">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Нийлүүлэгчийн имэйл" :class="changeLog(historyDetailoldData ? historyDetailoldData.supplier_email : historyDetailnewData.supplier_email, historyDetailnewData.supplier_email) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.supplier_email"></el-input>
                  </el-form-item>
                  <el-form-item label="Байгууллагын регистэр" :class="changeLog(historyDetailoldData ? historyDetailoldData.supplier_regno : historyDetailnewData.supplier_regno, historyDetailnewData.supplier_regno) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.supplier_regno"></el-input>
                  </el-form-item>
                  <el-form-item label="Утас" :class="changeLog(historyDetailoldData ? historyDetailoldData.phone : historyDetailnewData.phone, historyDetailnewData.phone) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="НӨАТ" :class="changeLog(historyDetailoldData ? this.oldEbarimtType : this.newEbarimtType, this.newEbarimtType) ? 'labelColor' : ''">
                    <el-input v-model="this.newEbarimtType"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Supplier name" :class="changeLog(historyDetailoldData ? historyDetailoldData.supplier_engName : historyDetailnewData.supplier_engName , historyDetailnewData.supplier_engName) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.supplier_engName"></el-input>
                  </el-form-item>
                  <el-form-item label="Хариуцсан менежер" :class="changeLog(historyDetailoldData ? historyDetailoldData.in_charge_manager : historyDetailnewData.in_charge_manager, historyDetailnewData.in_charge_manager) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.in_charge_manager"></el-input>
                  </el-form-item>
                  <el-form-item label="Байршил" :class="changeLog(historyDetailoldData ? historyDetailoldData.location : historyDetailnewData.location, historyDetailnewData.location) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.location"></el-input>
                  </el-form-item>
                  <el-form-item label="Хаяг" :class="changeLog(historyDetailoldData ? historyDetailoldData.address : historyDetailnewData.address, historyDetailnewData.address) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.address"></el-input>
                  </el-form-item>
                  <el-form-item label="Diamond код" :class="changeLog(historyDetailoldData ? historyDetailoldData.diamond_code : historyDetailnewData.diamond_code, historyDetailnewData.diamond_code) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.diamond_code"></el-input>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mt10">
        <el-col align="middle">
          <el-button type="danger" @click="exitHistoryDetail">Хаах</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link :to="'/supplier' + '?page=' + this.$route.query.page + '&size=' + this.$route.query.size + '&search=' + this.$route.query.search + '&field=' + this.$route.query.field + '&sort=' + this.$route.query.sort">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- delete -->
    <el-dialog
        :title="isChanging ? 'Нийлүүлэгч шинэчлэх' : 'Нийлүүлэгч устгах'"
        :visible.sync="confirmDeleteSupplierDialog"
        width="50%"
      >
        <div>
          <strong>{{supplierData.supplier_monName}}</strong> нийлүүлэгчийг {{ isChanging ? 'шинэчлэхдээ' : 'устгахдаа'}} шалтгаанаа заавал бичнэ үү?
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDeleteSupplierDialog = false" class="mr10"
            >Болих</el-button
          >
          <el-button  v-if="isChanging" :disabled="isDisabled" type="success" @click="onPressNewProdSupplier('supplierData', 'supplierRules')">
            Хадгалах
          </el-button>
          <el-button v-else type="danger" :disabled="isDisabled" @click="deleteSupplier('supplierData', 'supplierRules')">
            Устгах
          </el-button>
        </span>
        <el-row :gutter="20">
          <el-col :span="24" class="mt20">
            <el-form :model="supplierData" :rules="supplierRules" ref="supplierData">
              <el-form-item prop="desc" label="Шалтгаан">
                <el-input v-model="supplierData.desc" type="textarea" :rows="2" :placeholder="isChanging ? 'Шинэчилж буй шалтгаанаа бичнэ үү ...' : 'Устгаж буй шалтгаанаа бичнэ үү ...'"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-dialog>
    <!-- <el-dialog
      title="Нийлүүлэгч устгах"
      :visible.sync="confirmDeleteSupplierDialog"
      width="50%"
    >
      <div>
        <strong>{{supplierData.supplier_monName}}</strong> нийлүүлэгчийг устгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDeleteSupplierDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="danger" @click="deleteSupplier(supplierData.supplier_id)">
          Устгах
        </el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import Loader from '../../../components/common/loader.vue'
import customHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
export default {
  components: {
    customHeader: customHeader,
    Loader
  },

  data () {
    return {
      historyTotal: '',
      historyDetailoldData: null,
      historyDetailnewData: null,
      oldEbarimtType: null,
      newEbarimtType: null,
      historyLoading: false,
      selectedLogId: '',
      selectedLog: [],
      historyDetailData: [],
      historyData: [],
      historyName: 'supplier',
      dialogVisibleHistory: false,
      historyDetail: false,
      isDisabled: false,
      isChanging: false,
      loading: false,
      confirmDeleteSupplierDialog: false,
      confirmLeaveForm: false,
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      supplierData: {
        is_tax_paid: false,
        isActive: true,
        diamond_code: '',
        desc: '',
        package_connetion_check: false,
        ebarimt_type: '',
        brands: [],
        supplier_monName: '',
        supplier_engName: '',
        supplier_regno: '',
        EbarimtSupplier: '',
        phone: '',
        phone2: '',
        address: '',
        location: '47.9174, 106.9137',
        supplier_id: ''
      },
      googleMap: {
        center: {
          lat: 47.9174,
          lng: 106.9137
        },
        location: {
          lat: 47.9174,
          lng: 106.9137
        },
        markerOptions: {
          size: { width: 100, height: 90, f: 'px', b: 'px' },
          scaledSize: { width: 45, height: 45, f: 'px', b: 'px' }
        },
        options: {
          scrollwheel: true,
          mapTypeControl: true,
          streetViewControl: false,
          minZoom: 4,
          maxZoom: 20,
          gestureHandling: 'greedy'
        }
      },
      week: [
        {
          day: 'Даваа',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Мягмар',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Лхагва',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Пүрэв',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Баасан',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Бямба',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Ням',
          is_weekend: false,
          start_time: null,
          end_time: null
        }
      ],
      supplierRules: {
        supplier_monName: [
          { required: true, message: 'Байгууллагын монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: 'Шалтгаан заавал бөглөнө үү?', trigger: 'blur' }
        ],
        supplier_engName: [
          { required: true, message: 'Байгууллагын англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_regno: [
          { required: true, message: 'Байгууллагын регистрийн дугаараа бөглөнө үү', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу', trigger: 'blur' },
          { min: 6, max: 12, message: 'Утасны дугаараа бүрэн оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Хаягаа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  created () {
    if (this.$route.params.supplier_id) {
      this.getOneSupplier(this.$route.params.supplier_id)
      this.getHistory(this.$route.params.supplier_id)
    }
  },
  methods: {
    generateDateTime (day) {
      if (day.is_weekend === true) {
        day.start_time = null
        day.end_time = null
      }
    },
    onPressBrand (data) {
      this.$router.push({
        name: 'editBrand',
        params: {
          brand_id: data.brand_id
        }
      }).catch(() => {})
    },
    changeLog (old, shine) {
      if (old !== shine) {
        return true
      } else return false
    },
    getOneSupplier (id) {
      services.getOneSupplier(id).then(response => {
        if (response.status === 'success') {
          this.supplierData = response.data
          const location = this.supplierData.location.split(',')
          this.googleMap.location.lat = parseFloat(location[0])
          this.googleMap.location.lng = parseFloat(location[1])
          // this.week.forEach(day => {
          //   if (day.day === 'Даваа') {
          //     if (this.supplierData.work_time.monday.start_time === null || this.supplierData.work_time.monday.start_time === null) {
          //       day.is_weekend = true
          //     }
          //     day.start_time = this.supplierData.work_time.monday.start_time
          //     day.end_time = this.supplierData.work_time.monday.end_time
          //   } else if (day.day === 'Мягмар') {
          //     if (this.supplierData.work_time.tuesday.start_time === null || this.supplierData.work_time.tuesday.start_time === null) {
          //       day.is_weekend = true
          //     }
          //     day.start_time = this.supplierData.work_time.tuesday.start_time
          //     day.end_time = this.supplierData.work_time.tuesday.end_time
          //   } else if (day.day === 'Лхагва') {
          //     if (this.supplierData.work_time.wednesday.start_time === null || this.supplierData.work_time.wednesday.start_time === null) {
          //       day.is_weekend = true
          //     }
          //     day.start_time = this.supplierData.work_time.wednesday.start_time
          //     day.end_time = this.supplierData.work_time.wednesday.end_time
          //   } else if (day.day === 'Пүрэв') {
          //     if (this.supplierData.work_time.thursday.start_time === null || this.supplierData.work_time.thursday.start_time === null) {
          //       day.is_weekend = true
          //     }
          //     day.start_time = this.supplierData.work_time.thursday.start_time
          //     day.end_time = this.supplierData.work_time.thursday.end_time
          //   } else if (day.day === 'Баасан') {
          //     if (this.supplierData.work_time.friday.start_time === null || this.supplierData.work_time.friday.start_time === null) {
          //       day.is_weekend = true
          //     }
          //     day.start_time = this.supplierData.work_time.friday.start_time
          //     day.end_time = this.supplierData.work_time.friday.end_time
          //   } else if (day.day === 'Бямба') {
          //     if (this.supplierData.work_time.saturday.start_time === null || this.supplierData.work_time.saturday.start_time === null) {
          //       day.is_weekend = true
          //     }
          //     day.start_time = this.supplierData.work_time.saturday.start_time
          //     day.end_time = this.supplierData.work_time.saturday.end_time
          //   } else if (day.day === 'Ням') {
          //     if (this.supplierData.work_time.sunday.start_time === null || this.supplierData.work_time.sunday.start_time === null) {
          //       day.is_weekend = true
          //     }
          //     day.start_time = this.supplierData.work_time.sunday.start_time
          //     day.end_time = this.supplierData.work_time.sunday.end_time
          //   }
          // })
          this.loading = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },
    exitHistory () {
      this.dialogVisibleHistory = false
    },
    exitHistoryDetail () {
      this.historyDetail = false
      this.historyDetailnewData = null
      this.historyDetailoldData = null
    },
    getHistory (supplierId) {
      const query = '?id=' + supplierId + '&log_name=' + this.historyName
      services.getHistory(query).then(data => {
        this.historyData = data.data
        this.historyTotal = data.total
      })
    },
    getHistoryDetail (data) {
      this.historyLoading = true
      services.getHistoryDetail(data._id).then(response => {
        if (response.status === 'success' && response.data) {
          if (response.data.old) {
            switch (response.data.old.ebarimt_type) {
              case 'supplier':
                this.oldEbarimtType = 'Нийлүүлэгч'
                break
              case 'package_connetion':
                this.oldEbarimtType = 'Багцын'
                break
              case 'from_us':
                this.oldEbarimtType = 'Манайхаас'
                break
              case 'NOATgui':
                this.oldEbarimtType = 'НӨАТ-гүй'
            }
            this.historyDetailoldData = response.data.old
          }
          if (response.data.new) {
            switch (response.data.new.ebarimt_type) {
              case 'supplier':
                this.newEbarimtType = 'Нийлүүлэгч'
                break
              case 'package_connetion':
                this.newEbarimtType = 'Багцын'
                break
              case 'from_us':
                this.newEbarimtType = 'Манайхаас'
                break
              case 'NOATgui':
                this.newEbarimtType = 'НӨАТ-гүй'
            }
          }
          this.historyDetailnewData = response.data.new
          this.historyLoading = false
        } else {
          this.historyLoading = false
        }
      })
    },
    showHistoryDialog (data) {
      this.historyDetail = true
      this.getHistoryDetail(data)
    },
    onChange (type) {
      if (type === 'update') {
        this.confirmDeleteSupplierDialog = true
        this.isChanging = true
      } else {
        this.confirmDeleteSupplierDialog = true
        this.isChanging = false
      }
    },

    numberValidate () {
      if (this.supplierData.supplier_regno.length >= 7 && this.supplierData.supplier_regno.length <= 10) {
        this.checkEbarimt()
      }
    },

    checkEbarimt () {
      services.checkEbarimt(this.supplierData.supplier_regno).then(response => {
        if (response.found === true) {
          this.supplierData.EbarimtSupplier = response.name
          this.$forceUpdate()
        }
      })
    },

    changeLocation (event) {
      this.supplierData.location = event.lat() + ', ' + event.lng()
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    onPressNewProdSupplier (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.isDisabled = true
          if (this.supplierData.supplier_id !== '') {
            // this.supplierData.work_time = this.generateWorkTime()
            services.updateSupplier(this.supplierData).then(data => {
              this.isDisabled = false
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.loading = false
                this.$router.push({ name: 'supplier' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          } else {
            services.addSupplier(this.supplierData).then(data => {
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.loading = false
                this.$router.push({ name: 'supplier' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          }
        } else {
          console.log('error submit!!!!!')
          return false
        }
      })
    },

    // generateWorkTime () {
    //   const workingTime = {}
    //   this.week.forEach(day => {
    //     if (day.day === 'Даваа') {
    //       workingTime.monday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Мягмар') {
    //       workingTime.tuesday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Лхагва') {
    //       workingTime.wednesday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Пүрэв') {
    //       workingTime.thursday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Баасан') {
    //       workingTime.friday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Бямба') {
    //       workingTime.saturday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Ням') {
    //       workingTime.sunday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     }
    //   })
    //   return workingTime
    // },

    deleteSupplier (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.isDisabled = true
          services.deleteSupplier({
            supplierId: this.supplierData.supplier_id,
            desc: this.supplierData.desc
          }).then(response => {
            this.isDisabled = false
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', `${this.supplierData.supplier_id} дугаартай нийлүүлэгч устлаа`, 'success')
              this.loading = false
              this.$router.push({ name: 'supplier' })
            } else if (response.status === 'error') {
              this.alertReporter('Алдаа', `${response.message}`)
              this.loading = false
            } else {
              return false
            }
          })
        }
      })
    }
  }

}
</script>

<style>
.labelColor .el-form-item__label {
  color: red;
}
</style>
